<template>
  <div class="container">
    <user-nav/>

    <div class="main">
      <div class="main__intro">
        <div class="main__intro-text">
          <h1 class="main__intro-text__title">
            Наш сервис поможет вам проверить надежность вашей сделки и подтвердить безопасность инвестиций в недвижимость
          </h1>
        </div>

        <router-link
          tag="button"
          class="btn main__intro-btn"
          to="/shop"
        >НАЧАТЬ</router-link>
      </div>

      <img class="main__intro-poster" src="@/assets/img/home-poster.png">
    </div>
  </div>
</template>

<script>

import UserNav from '@/components/UserNav'

export default {
  name: 'Home',
  components: {
    UserNav
  }
}
</script>

<style lang="scss">
.main__intro {
  display: flex;
  flex-direction: column;

  @include sm {
    margin-top: 60px;
  }
}

.main__intro-text {
  position: relative;
}

.main__intro-text__title {
  text-align: left;
  font-weight: 600;
  margin: 25px 0;

  @include sm {
    max-width: 100%;
    font-size: 18px;
  }

  @include md {
    max-width: 100%;
    font-size: rem-md(18px);
  }

  @include lg {
    max-width: 50%;
    font-size: rem-lg(20px);
  }
}

.main__intro-btn {
  max-width: 220px;
  height: 45px;
  border-radius: 30px;
  margin-top: 40px;
  @include sm {
    min-width: 220px;
    margin: 40px auto 0 auto;
  }
}


.main__intro-poster {
  position: relative;

  @include sm {
    width: rem-sm(210px);
    min-width: 300px;
    margin: 30px auto 0 auto;
  }
  @include md {
    width: 70%;
    margin: 30px auto 0 auto;
  }
  @include lg {
    height: 50vh;
    transform: translate(30%, -20%);
  }
}

.link {
  display: block;
  font-size: 12px;
  text-align: left;
  color: black;
}
</style>
